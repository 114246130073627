var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c("div", [
            _vm.canVerb(_vm.resource, "index")
              ? _c(
                  "div",
                  { staticClass: "mx-1" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { align: "left" } },
                          [
                            _c(
                              "b-button-group",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                staticClass: "my-2 filter-button-group",
                                attrs: { title: _vm.getDefaultFilterMessage() },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "when-open" },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "funnel",
                                        "font-scale": "1.5",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "when-closed" },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "funnel",
                                        "font-scale": "1.5",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v("\n            Filtra\n          "),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { align: "right" } },
                          [
                            _vm.canVerb(_vm.resource, "store")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-create",
                                    attrs: {
                                      type: "button",
                                      variant: "primary",
                                      size: "sm",
                                      title: "Crea",
                                    },
                                    on: { click: _vm.onAdd },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "plus-circle",
                                        "aria-hidden": "true",
                                      },
                                    }),
                                    _vm._v("\n            Crea"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-collapse",
                      { attrs: { visible: "", id: "collapse-1" } },
                      [
                        _c(
                          "div",
                          { staticClass: "mt-2", attrs: { id: "collapse-1" } },
                          [
                            _c(
                              "b-form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onSearch(_vm.filterName)
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-card",
                                  { staticClass: "filter" },
                                  [
                                    _c("b-row", [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3" },
                                        [
                                          _c("base-input", {
                                            attrs: {
                                              name: "titolo",
                                              type: "text",
                                              label: "Descrizione",
                                              placeholder:
                                                "Inserisci una descrizione",
                                            },
                                            model: {
                                              value:
                                                _vm.filter.byAttribute.title,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter.byAttribute,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filter.byAttribute.title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3" },
                                        [
                                          _c("base-select", {
                                            attrs: {
                                              name: "status_payment",
                                              label: "Stato",
                                              options:
                                                _vm.status_payment_options,
                                            },
                                            model: {
                                              value:
                                                _vm.filter.byAttribute
                                                  .status_payment,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter.byAttribute,
                                                  "status_payment",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filter.byAttribute.status_payment",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3" },
                                        [
                                          _c("base-datepicker", {
                                            attrs: {
                                              name: "effective_date",
                                              label: "Data",
                                            },
                                            model: {
                                              value: _vm.filter.byCalendar.from,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter.byCalendar,
                                                  "from",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filter.byCalendar.from",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("b-row", [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3" },
                                        [
                                          _c("base-input", {
                                            attrs: {
                                              name: "SURN",
                                              type: "text",
                                              label: _vm.getDictionary(
                                                "attribute_SURN_value",
                                                "registry"
                                              ),
                                              placeholder:
                                                "Inserisci un cognome",
                                            },
                                            model: {
                                              value: _vm.filter.byRegistry.SURN,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter.byRegistry,
                                                  "SURN",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filter.byRegistry.SURN",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3" },
                                        [
                                          _c("base-input", {
                                            attrs: {
                                              name: "NAME",
                                              type: "text",
                                              label: _vm.getDictionary(
                                                "attribute_NAME_value",
                                                "registry"
                                              ),
                                              placeholder: "Inserisci un nome",
                                            },
                                            model: {
                                              value: _vm.filter.byRegistry.NAME,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter.byRegistry,
                                                  "NAME",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filter.byRegistry.NAME",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3" },
                                        [
                                          _c("base-input", {
                                            attrs: {
                                              name: "creg",
                                              type: "text",
                                              label: _vm.getDictionary(
                                                "attribute_CREG_value",
                                                "registry"
                                              ),
                                              placeholder:
                                                "Inserisci una partita IVA",
                                            },
                                            model: {
                                              value: _vm.filter.byRegistry.CREG,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter.byRegistry,
                                                  "CREG",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filter.byRegistry.CREG",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group col-md-3 align-self-end",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle:collapse-1",
                                                  arg: "collapse-1",
                                                },
                                              ],
                                              attrs: {
                                                type: "submit",
                                                variant: "lisaweb",
                                                size: "sm",
                                              },
                                            },
                                            [_vm._v("Cerca")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "btn-reset",
                                              attrs: {
                                                type: "button",
                                                variant: "lisaweb",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onClearFilter(
                                                    _vm.filterName
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Reset")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table-unsolved", {
                      ref: _vm.tableRef,
                      attrs: {
                        filterOn: {
                          byRelations: ["byRegistry"],
                        },
                        fields: _vm.fields,
                        repository: _vm.repository,
                        resource: _vm.resource,
                        filterName: _vm.filterName,
                        onlyActions: ["edit", /* 'infomodal', */ "destroy"],
                        hasChecks: "",
                      },
                      on: { edit: _vm.onEdit, destroy: _vm.onDestroy },
                    }),
                    _vm._v(" "),
                    _c("b-row", [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-3" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "button",
                                variant: "lisaweb",
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                            },
                            [_vm._v("Incassa")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("select-treasury-modal", {
                          attrs: { opts: _vm.treasury_options },
                          on: { treasury: _vm.onTreasury },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("b-icon", {
                      attrs: { icon: "info-circle", scale: "1.00" },
                    }),
                    _vm._v("\n      Non hai i permessi\n    "),
                  ],
                  1
                ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }