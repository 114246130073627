var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "selectTreasury",
        size: "sm",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "cash" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Casse")]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          _vm.$emit("treasury")
                          cancel()
                        },
                      },
                    },
                    [_vm._v("ANNULLA")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c("b-row", [
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _vm._v(
            "\n      Per procedere con il salvataggio, è necessario selezionare una cassa che\n      verrà utilizzata per ciascun insoluto.\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("b-row", [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("base-select", {
              attrs: {
                name: "status_payment",
                label: "Seleziona una cassa",
                options: _vm.treasury_options,
              },
              on: { select: _vm.onSelectTreasury },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }